<template>
  <div class="page page-upload">
    <div style="height: 20px"></div>
    <el-row>
      <el-col :span="24">
        <el-card shadow="never">
          <div slot="header" class="clearfix">
            <strong>上传文档</strong>
          </div>
          <el-row :gutter="20">
            <el-col
              :span="setKeywordsAndDescription && fileList.length > 0 ? 24 : 14"
              class="part-left"
              :class="
                setKeywordsAndDescription && fileList.length > 0
                  ? 'hide-border'
                  : ''
              "
            >
              <el-form
                ref="form"
                :model="document"
                label-position="top"
                label-width="80px"
              >
                <el-row :gutter="20">
                  <el-col :span="12">
                    <el-form-item
                      label="文档分类"
                      prop="category_id"
                      :rules="[
                        {
                          required: true,
                          trigger: 'blur',
                          message: '请选择文档分类',
                        },
                      ]"
                    >
                      <el-cascader
                        v-model="document.category_id"
                        :options="categoryTrees"
                        :filterable="true"
                        :disabled="loading"
                        :props="{
                          checkStrictly: true,
                          expandTrigger: 'hover',
                          label: 'title',
                          value: 'id',
                        }"
                        placeholder="请选择文档分类"
                      ></el-cascader>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item prop="price">
                      <template slot="label">
                        <span>{{ `默认售价（元）` }}</span>
                        <ToolTip
                          content="如果您不想为每个文档单独设置售价，可以在此处设置默认售价"
                        />
                      </template>
                      <el-input-number
                        v-model="document.price"
                        :min="0"
                        :step="1"
                        :precision="2"
                        :disabled="loading"
                        @change="setprice"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-form-item prop="isDir">
                      <el-checkbox
                        v-model="isDir"
                        @change="changeDir"
                        :disabled="loading"
                        >上传文件夹</el-checkbox
                      >
                    </el-form-item>
                  </el-col>
                  <!-- <el-col :span="8">
                    <el-form-item prop="is_vip">
                      <el-checkbox
                        v-model="isVIP"
                        @change="changeVIP"
                        :disabled="loading"
                        >加入VIP文档</el-checkbox
                      >
                    </el-form-item>
                  </el-col> -->
                  <el-col :span="8">
                    <el-form-item>
                      <el-checkbox
                        v-model="setKeywordsAndDescription"
                        :disabled="loading"
                        >设置文档关键字与摘要</el-checkbox
                      >
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item>
                  <el-upload
                    ref="upload"
                    drag
                    multiple
                    :action="'/api/v1/upload/document'"
                    :headers="{ authorization: `Bearer ${token}` }"
                    :show-file-list="false"
                    :disabled="loading || !canIUploadDocument"
                    :auto-upload="false"
                    :on-change="onChange"
                    :file-list="fileList"
                  >
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">
                      <template v-if="isDir"
                        >点击选择需要上传的<em style="color: #23dc5a"
                          >文件夹</em
                        ></template
                      >
                      <template v-else
                        >将文件拖到此处，或<em style="color: #23dc5a"
                          >点击上传</em
                        ></template
                      >
                    </div>
                  </el-upload>
                  <vxe-table
                    v-if="fileList.length > 0"
                    :data="fileList"
                    style="width: 100%"
                    max-height="480"
                    stripe
                    border="inner"
                    :column-config="{ resizable: true }"
                    :row-config="{ height: 110 }"
                  >
                    <vxe-column type="seq" width="60"></vxe-column>
                    <vxe-column field="title" title="文档" min-width="180">
                      <template #header>
                        文档 (<el-button
                          type="text"
                          size="mini"
                          :disabled="loading"
                          @click="clearAllFiles"
                          :style="{ color: buttonTextColor }"
                          >清空</el-button
                        >)
                      </template>
                      <template #default="{ row, rowIndex }">
                        <el-input v-model="row.title" :disabled="loading">
                          <template slot="append">{{
                            row.ext
                          }}</template></el-input
                        >
                        <div v-if="row.error">
                          <el-progress
                            :key="row.name"
                            :percentage="row.percentage"
                            status="exception"
                          ></el-progress>
                          <small class="el-link el-link--danger error-tips">{{
                            row.error
                          }}</small>
                        </div>
                        <el-progress
                          v-else-if="row.percentage > 0"
                          :percentage="row.percentage"
                        ></el-progress>
                        <div class="table-action">
                          <!-- <el-checkbox v-model="row.is_vip" :disabled="loading"
                            >加入VIP文档</el-checkbox
                          > -->
                          <!-- &nbsp; -->
                          <el-button
                            size="mini"
                            type="text"
                            icon="el-icon-delete"
                            :disabled="loading"
                            @click="handleRemove(rowIndex)"
                            :style="{ color: buttonTextColor }"
                            >移除文档</el-button
                          >
                        </div>
                      </template>
                    </vxe-column>
                    <vxe-column field="size" title="大小" width="100" sortable>
                      <template #default="{ row }">
                        <span>{{ formatBytes(row.size) }}</span>
                      </template>
                    </vxe-column>
                    <vxe-column
                      field="price"
                      :title="`售价(整数/元)`"
                      :width="130"
                      sortable
                    >
                      <template #default="{ row }">
                        <el-input-number
                          v-model="row.price"
                          :min="0"
                          :step="1"
                          :disabled="loading"
                          controls-position="right"
                        ></el-input-number>
                      </template>
                    </vxe-column>
                    <template v-if="setKeywordsAndDescription">
                      <vxe-column field="keywords" title="关键字" width="250">
                        <template #default="{ row }">
                          <el-input
                            v-model="row.keywords"
                            :disabled="loading"
                            type="textarea"
                            :rows="3"
                            placeholder="请输入关键字，多个关键字请用英文逗号分隔"
                          ></el-input>
                        </template>
                      </vxe-column>
                      <vxe-column field="description" title="摘要" width="250">
                        <template #default="{ row }">
                          <el-input
                            v-model="row.description"
                            :disabled="loading"
                            type="textarea"
                            :rows="3"
                            placeholder="请输入文档摘要，用以描述和介绍文档"
                          ></el-input>
                        </template>
                      </vxe-column>
                    </template>
                  </vxe-table>
                </el-form-item>
                <el-alert
                  title="告警提示"
                  type="warning"
                  class="tips-alert"
                  :closable="false"
                  v-if="fileMessages.length > 0"
                >
                  <div
                    v-for="(message, index) in fileMessages"
                    :key="'msg-' + index"
                  >
                    {{ message }}
                  </div>
                </el-alert>
                <el-form-item style="margin-bottom: 0">
                  <el-button
                    v-if="canIUploadDocument"
                    type="primary"
                    class="btn-block"
                    :loading="loading"
                    @click="onSubmit"
                    :style="{
                      background: btnBackGround,
                      border: 'none',
                      width: '100%',
                    }"
                  >
                    <span v-if="loading">请勿刷新页面，文档上传中...</span>
                    <span
                      v-else
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <img
                        src="@/assets/image/icon119.png"
                        alt=""
                        style="width: 25px; height: 25px"
                      />
                      确定上传</span
                    >
                  </el-button>
                  <el-button
                    v-else
                    type="primary"
                    icon="el-icon-hot-water"
                    class="btn-block"
                    disabled
                  >
                    <span v-if="user.id > 0">您暂无权限上传文档</span>
                    <span v-else>您未登录，请先登录</span>
                  </el-button>
                </el-form-item>
              </el-form>
            </el-col>
            <el-col
              :span="10"
              v-if="
                !(setKeywordsAndDescription && fileList.length > 0) || isMobile
              "
              class="upload-tips part-right"
            >
              <div><strong>温馨提示</strong></div>
              <div class="help-block">
                <ul>
                  <li>
                    1. 带有
                    <span class="el-link el-link--danger">*</span> 为必填项。
                  </li>
                  <li>
                    <!-- 应该从管理后台的配置中查询 -->
                    2. 允许上传的最大单个文档大小为：<span
                      class="el-link el-link--primary"
                      style="color: #23dc5a"
                      >{{
                        settings.security.max_document_size.toFixed(2) ||
                        "50.00"
                      }}
                      MB</span
                    >
                    。
                  </li>
                  <li>3. 支持批量上传</li>
                  <!-- <li>
                      4.
                      <span class="el-link el-link--danger">同名覆盖</span>
                      表示相同名称的文档（含扩展名），直接用新文档文件替换，以达到更新文档文件的目的。
                    </li> -->
                  <li>
                    4. 目前支持的文档类型：
                    <div
                      v-if="wordExt.length > 0"
                      style="color: #999999; font-size: 14px"
                    >
                      <img
                        src="@/assets/image/icon113.png"
                        style="width: 28px; height: 28px"
                        alt="Word文档"
                      />
                      {{ wordExt.join("，") }}
                    </div>
                    <div
                      v-if="pptExt.length > 0"
                      style="color: #999999; font-size: 14px"
                    >
                      <img
                        src="@/assets/image/icon116.png"
                        style="width: 28px; height: 28px"
                        alt="PPT文档"
                      />
                      {{ pptExt.join("，") }}
                    </div>
                    <div
                      v-if="excelExt.length > 0"
                      style="color: #999999; font-size: 14px"
                    >
                      <img
                        src="@/assets/image/icon114.png"
                        style="width: 28px; height: 28px"
                        alt="Excel文档"
                      />
                      {{ excelExt.join("，") }}
                    </div>
                    <div
                      v-if="otherExt.length > 0"
                      style="color: #999999; font-size: 14px"
                    >
                      <img
                        src="@/assets/image/icon117.png"
                        style="width: 28px; height: 28px"
                        alt="其他文档"
                      />
                      {{ otherExt.join("，") }}
                    </div>
                    <div
                      v-if="allowExt.includes('.txt')"
                      style="color: #999999; font-size: 14px"
                    >
                      <img
                        src="@/assets/image/icon115.png"
                        style="width: 28px; height: 28px"
                        alt="TXT文档"
                      />
                      .txt
                    </div>
                    <div
                      v-if="allowExt.includes('.pdf')"
                      style="color: #999999; font-size: 14px"
                    >
                      <img
                        src="@/assets/image/icon118.png"
                        style="width: 28px; height: 28px"
                        alt="PDF文档"
                      />
                      .pdf
                    </div>
                  </li>
                  <li>
                    5. 上传遇到问题需要帮助？请查看
                    <span
                      to="/article/help"
                      class="el-link el-link--primary"
                      style="color: #23dc5a"
                      @click="jumpNoticDetail('help')"
                      >文库帮助</span
                    >
                    和
                    <span
                      to="/article/feedback"
                      class="el-link el-link--primary"
                      style="color: #23dc5a"
                      @click="jumpNoticDetail('feedback')"
                      >意见反馈</span
                    >
                  </li>
                  <li>
                    6. 为营造绿色网络环境，严禁上传含有淫秽色情及低俗信息等文档
                  </li>
                  <li>
                    7.
                    对于涉嫌侵权和违法违规的文档，本站有权在不提前通知的情况下对文档进行删除，您在本站上传文档，表示认同该条款
                  </li>
                  <li>
                    8.编辑价格时，请查看
                    <span
                      style="color: #23dc5a; font-size: 14px; cursor: pointer"
                      @click="jumpRichText()"
                      >固定的价格指导表</span
                    >
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <div style="height: 50px"></div>
  </div>
</template>
  
  <script>
import { calcMD5, formatBytes, categoryToTrees } from "/utils/utils";
// import { uploadDocument } from "~/api/attachment";
import { get, postJSON } from "/utils/request";

export default {
  data() {
    return {
      canIUploadDocument: false,
      document: {
        category_id: [],
        price: 0,
        overwrite: false,
      },
      isVIP: false,
      setKeywordsAndDescription: false,
      maxDocumentSize: 50 * 1024 * 1024,
      fileList: [],
      filesMap: {},
      loading: false,
      percentAge: 0,
      allowExt: [
        ".doc",
        ".docx",
        ".rtf",
        ".wps",
        ".odt",
        ".dot",
        ".ppt",
        ".pptx",
        ".pps",
        ".ppsx",
        ".dps",
        ".odp",
        ".pot",
        ".xls",
        ".xlsx",
        ".csv",
        ".tsv",
        ".et",
        ".ods",
        ".epub",
        ".umd",
        ".chm",
        ".mobi",
        ".txt",
        ".pdf",
      ],
      wordExtEnum: [".doc", ".docx", ".rtf", ".wps", ".odt", ".dot"],
      pptExtEnum: [".ppt", ".pptx", ".pps", ".ppsx", ".dps", ".odp", ".pot"],
      excelExtEnum: [".xls", ".xlsx", ".csv", ".tsv", ".et", ".ods"],
      otherExtEnum: [".epub", ".umd", ".chm", ".mobi"],
      wordExt: [],
      pptExt: [],
      excelExt: [],
      otherExt: [],
      totalFiles: 0, // 总个数
      totalFailed: 0, // 失败个数
      totalSuccess: 0, // 成功个数
      totalDone: 0, // 完成个数
      fileMessages: [],
      isDir: false,
      user: {},
      categoryTrees: [],
      settings: {},
      token: window.localStorage.getItem("token"),
      buttonTextColor: "#23DC5A",
      btnBackGround: "linear-gradient(90deg,#23c48c, #33da61)",
    };
  },
  head() {
    return {
      title: "上传文档 - " + this.settings.system.title || "郁金香文库",
      meta: [
        {
          hid: "keywords",
          name: "keywords",
          content: `上传文档,${this.settings.system.sitename},${this.settings.system.keywords}`,
        },
        {
          hid: "description",
          name: "description",
          content: this.settings.system.description,
        },
      ],
    };
  },
  computed: {
    // ...mapGetters("user", ["token", "user"]),
    // ...mapGetters("category", ["categoryTrees"]),
    // ...mapGetters("setting", ["settings"]),
  },
  async created() {
    const res = await get("/user/caniuploaddocument");
    if (Object.getOwnPropertyNames(res).length === 0) {
      this.canIUploadDocument = true;
    }
    try {
      this.maxDocumentSize =
        (this.settings.security.max_document_size || 50) * 1024 * 1024;
    } catch (error) {
      console.log(error);
    }

    try {
      this.allowExt =
        this.settings.security.document_allowed_ext || this.allowExt;
    } catch (error) {
      console.log(error);
    }
    this.allowExt.map((ext) => {
      if (this.wordExtEnum.includes(ext)) {
        this.wordExt.push(ext);
      } else if (this.pptExtEnum.includes(ext)) {
        this.pptExt.push(ext);
      } else if (this.excelExtEnum.includes(ext)) {
        this.excelExt.push(ext);
      } else if (this.otherExtEnum.includes(ext)) {
        this.otherExt.push(ext);
      }
    });
  },
  mounted() {
    this.getUserInfo();
    this.getCategoryList();
    get("/settings", {}).then((res) => {
      console.log(res);
      this.settings = res ?? [];
    });
  },
  methods: {
    formatBytes,
    async getUserInfo() {
      // /api/v1/user
      let res = await get("/info/user", {});
      console.log("res", res);
      this.user = res.data;
    },
    async getCategoryList() {
      let res = await get("/category/list", {});
      console.log(res);
      this.categoryTrees = categoryToTrees(res.category);
    },
    // 设置是否允许选择文件夹上传
    changeDir() {
      const upload = this.$refs.upload.$el.querySelector("input[type=file]");
      if (this.isDir) {
        upload.setAttribute("webkitdirectory", "webkitdirectory");
        upload.setAttribute("directory", "directory");
      } else {
        upload.removeAttribute("webkitdirectory");
        upload.removeAttribute("directory");
      }
    },
    onChange(file) {
      const name = file.name.toLowerCase();
      const ext = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
      if (!this.allowExt.includes(ext)) {
        this.fileMessages.unshift(
          `${file.name} 不支持的文件格式，已忽略该文件`
        );
        return;
      }

      if (file.size > this.maxDocumentSize) {
        this.fileMessages.unshift(
          `${file.name} 文件大小${formatBytes(
            file.size
          )} 超过限制（最大${formatBytes(this.maxDocumentSize)}），已忽略该文件`
        );
        return;
      }

      // 文件不能大于指定的文件大小
      if (
        !this.filesMap[name] &&
        this.allowExt.includes(ext) &&
        file.size <= this.maxDocumentSize
      ) {
        const item = {
          ...file,
          title: file.name.substring(0, file.name.lastIndexOf(".")),
          ext,
          price: this.document.price || 0,
          is_vip: this.isVIP || false,
          progressStatus: "success",
          error: "",
          percentage: 0,
          attachment_id: 0,
        };
        this.filesMap[name] = item;
        this.fileList.push(item);
        this.totalFiles = this.fileList.length;
      }
    },
    setprice() {
      this.fileList.map((item) => {
        item.price = this.document.price
      })
    },
    handleRemove(index) {
      this.filesMap[this.fileList[index].name] = null;
      this.fileList.splice(index, 1);
    },
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 1. 验证文件是否存在
          if (this.fileList.length === 0) {
            this.$message.error("请先选择要上传的文档");
            return;
          }

          this.totalFiles = this.fileList.length;
          this.totalDone = 0;
          this.loading = true;
          //   try {
          //     // 取消之前上传的请求，不然一直pending，新请求会没法发送
          //     window.uploadDocumentCancel.map((c) => c());
          //     window.uploadDocumentCancel = [];
          //   } catch (error) {
          //     console.log("error", error);
          //   }

          // chrome 等浏览器同一域名下最多只能同时发起 6 个请求，所以这里将 fileList 拆分成多个数组，每个数组的长度为 2，以便控制并发，每次只同时上传 2 个文件
          const fileList = this.fileList.reduce((prev, cur, index) => {
            const i = Math.floor(index / 2);
            prev[i] = prev[i] || [];
            prev[i].push(cur);
            return prev;
          }, []);
          fileList.reduce(async (prev, cur) => {
            await prev;
            await Promise.all(
              cur.map(async (file) => {
                await this.uploadDocument(file);
              })
            );
          }, Promise.resolve());
        }
      });
    },
    clearAllFiles() {
      if (this.loading) {
        return;
      }
      this.fileList = [];
      this.filesMap = {};
      this.fileMessages = [];
      this.$refs.upload.clearFiles();
    },
    async uploadDocument(file) {
      // 计算文档md5，如果文档已经存在，则不上传
      if (this.settings.security.disable_upload_exist_document) {
        let md5;
        try {
          md5 = await calcMD5(file.raw);
        } catch (error) {
          this.$message.error(`文档《${file.name}》md5计算失败:` + error);
          return;
        }

        const res = await postJSON("/document/exist", { hash: md5 });
        // { hash: md5 }
        // 返回200，说明文档已经存在，跳过上传
        if (res.status === 200) {
          this.$message.warning(`文档《${file.name}》已存在，跳过上传`);
          this.totalDone++;
          this.removeFile(file);
          if (this.totalDone === this.totalFiles) {
            this.loading = false;
          }
          return;
        }
      }

      if (file.percentage === 100 && file.attachment_id) {
        // 不用再次上传
        this.createDocument(file);
        this.totalDone++;
        if (this.totalDone === this.totalFiles) {
          this.loading = false;
        }
        return;
      }
      file.error = "";
      file.progressStatus = "success";

      const formData = new FormData();
      formData.append("file", file.raw);
      const res = await postJSON("/upload/document", formData, {
        onUploadProgress: (progressEvent) => {
          file.percentage = parseInt(
            (progressEvent.loaded / progressEvent.total) * 100
          );
        },
        // timeout: 1000 * 6,
      });
      console.log("上传返回", res);
      if (res.code == 200) {
        file.attachment_id = res.data.id || 0;
        this.createDocument(file);
        this.totalSuccess++;
      } else {
        console.log(res.data.message, "123123");
        file.progressStatus = "exception";
        file.error = res.data.message || res.statusText;
        this.$message.error(`《${file.name}》${file.error}`);
        this.totalFailed++;
      }

      //   try {
      //     // const res = await postJSON(
      //     //   "/upload/document",
      //     //   (formData,
      //     //   {
      //     //     onUploadProgress: (progressEvent) => {
      //     //       file.percentage = parseInt(
      //     //         (progressEvent.loaded / progressEvent.total) * 100
      //     //       );
      //     //     },
      //     //     // timeout: 1000 * 6,
      //     //   })
      //     // );
      //     const res = await postJSON("/upload/document",formData,{
      //         onUploadProgress: (progressEvent) => {
      //           file.percentage = parseInt(
      //             (progressEvent.loaded / progressEvent.total) * 100
      //           );
      //         },
      //         // timeout: 1000 * 6,
      //       }
      //     );
      //     console.log('上传返回',res);
      //     if (res.code === 200) {
      //       file.attachment_id = res.data.data.id || 0;
      //       this.createDocument(file);
      //       this.totalSuccess++;
      //     } else {
      //       file.progressStatus = "exception";
      //       file.error = res.data.message || res.statusText;
      //       this.$message.error(`《${file.name}》${file.error}`);
      //       this.totalFailed++;
      //     }
      //   } catch (error) {
      //     file.progressStatus = "exception";
      //     file.error = "上传失败或超时，请重试";
      //     this.$message.error(`《${file.name}》${file.error}`);
      //     this.totalFailed++;
      //   }

      this.totalDone++;
      if (this.totalDone === this.totalFiles) {
        this.loading = false;
      }
    },
    async createDocument(doc) {
      const createDocumentRequest = {
        overwrite: this.document.overwrite,
        category_id: this.document.category_id,
        document: [
          {
            title: doc.title,
            price: doc.price,
            is_vip: this.user.is_vip == 1 ? 1 : 2,
            keywords: doc.keywords,
            description: doc.description,
            attachment_id: doc.attachment_id,
          },
        ],
      };
      console.log("createDocumentRequest", createDocumentRequest);
      // const res = await postJSON("/create/document", createDocumentRequest);
      // if (res.message == "success") {
      //   // 从 fileList 中剔除 attachment_id 与当前文档相同的文档
      //   this.$message.success(`《${doc.title}》上传成功`);
      //   this.removeFile(doc);
      // } else {
      //   this.$message.error(`《${doc.title}》上传失败 ` + res.message);
      // }
      const res = await postJSON("/document", createDocumentRequest);
      if (Object.getOwnPropertyNames(res).length === 0) {
        // 从 fileList 中剔除 attachment_id 与当前文档相同的文档
        this.$message.success(`《${doc.title}》上传成功`);
        this.removeFile(doc);
      } else {
        this.$message.error(`《${doc.title}》上传失败 `);
      }
    },
    // 剔除文档
    removeFile(file) {
      this.fileList = this.fileList.filter((item) => {
        return item.name !== file.name;
      });
      this.filesMap = Object.keys(this.filesMap).reduce((acc, key) => {
        if (this.filesMap[key].name !== file.name) {
          acc[key] = this.filesMap[key];
        }
        return acc;
      }, {});
    },
    changeVIP() {
      this.fileList.forEach((file) => {
        file.is_vip = this.isVIP;
      });
    },
    jumpNoticDetail(item) {
      console.log(item);
      this.$router.push({
        path: "/noticeDetail",
        query: {
          identifier: item,
        },
      });
    },
    jumpRichText() {
      this.$router.push({ path: "/richText", query: { text: "jgzdb" } });
    },
  },
};
</script>
<style lang="scss" >
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #23dc5a !important;
  background-color: #23dc5a !important;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #23dc5a !important;
}
.el-checkbox__inner:hover {
  border-color: #23dc5a !important;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #23dc5a !important;
}

.page-upload {
  width: 1400px;
  margin: 0 auto;
  .tips-alert {
    margin-bottom: 20px;
    .el-alert__content {
      width: 100%;
      .el-alert__description {
        width: 100%;
        max-height: 200px;
        overflow: auto;
      }
    }
  }
  .vxe-table {
    .el-input-number {
      width: 100%;
    }
    .vxe-header--column {
      .vxe-cell {
        white-space: normal;
      }
    }
  }
  .el-table {
    .el-input-number {
      width: 120px;
    }
  }
  .el-progress {
    position: absolute;
    width: 100%;
    bottom: -1px;
  }
  .error-tips {
    font-size: 12px;
  }
  .part-left {
    border-right: 1px dashed rgb(252, 155, 91);
    &.hide-border {
      border-right: none;
    }
  }
  .upload-tips {
    line-height: 160%;
    padding-left: 20px !important;
    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      margin: 4px 0;
    }
    .el-link {
      top: -2px;
    }
    img {
      position: relative;
      top: 5px;
      width: 18px;
      height: 18px;
    }
  }
  .table-action {
    margin-top: 8px;
    font-size: 14px;
    .file-size {
      display: inline-block;
      margin: 0 8px;
      color: #999;
    }
  }
}
@media screen and (max-width: 768px) {
  .page-upload {
    .part-left {
      border-right: 0;
      width: 100% !important;
      .el-upload {
        display: block;
        .el-upload-dragger {
          width: 100% !important;
        }
      }
    }
    .part-right {
      width: 100% !important;
      margin-top: 20px;
      li {
        margin-bottom: 0;
      }
    }
    .upload-tips {
      border-left: 0;
      padding-left: 10px !important;
    }
  }
}
</style>
  